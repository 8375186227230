<script>
	import Image from '$lib/Components/Layout/Image/Image.svelte';
	import Section from '$lib/Components/Layout/Section/Section.svelte';
	import PostalCode from '$lib/PageSections/Forms/PostalCode/PostalCode.svelte';

	import residentialImage from './images/above_sink_installation.jpg?w=500';

	let {
		data = null,
		form = null,
		title = 'Get a Free Estimate',
		backgroundColor = 'blue',
		id = '',
		image,
	} = $props();
</script>

<Section {backgroundColor} {id}>
	<h2>{title}</h2>
	{#if image}{@render image()}{:else}
		<Image
			src={residentialImage}
			loading="lazy"
			class="no-bleed mb-4 max-w-xl rounded-lg shadow-lg"
		/>
	{/if}
	<PostalCode
		{data}
		{form}
		buttonText="CREATE COMFORT NOW"
		buttonTextLead="CONTACT ME ABOUT AN ESTIMATE"
	/>

	<p>
		We value safety and privacy. We will never share, sell, or rent your data to
		third parties not directly related to your purchase or consideration of our
		products. <a href="/privacy-policy">Read our full policy here.</a>
	</p>
</Section>
